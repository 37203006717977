@use 'reference';

@include reference.root;

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

html {
  font-size: 16px;
  overflow-y: scroll;
}

body {
  --background-color: #{reference.$color-white};
  --color: #{reference.$color-grey-400};

  -webkit-font-smoothing: antialiased;
  background-color: var(--background-color);
  color: var(--color);
  font-size: reference.$font-size-body;
  line-height: 1.5;
  margin: 0;

  @include reference.dark-mode {
    --background-color: #{reference.$color-grey-100};
  }
}

body,
button,
input,
select {
  color: var(--color);
  font-family: var(--font-family-primary);
}

* {
  @media (prefers-reduced-motion) {
    transition-duration: 0 !important;
  }
}

::selection {
  background: reference.$color-primary-25;
}

a {
  color: var(--color);
  transition: color reference.$transition-duration-medium ease-in-out;
}

button {
  cursor: pointer;
}

h1:not([class]) {
  @include reference.type-heading-1;
}

h2:not([class]) {
  @include reference.type-heading-2;
}

h3:not([class]) {
  @include reference.type-heading-3;
}

h4:not([class]) {
  @include reference.type-heading-4;
}

h5:not([class]) {
  @include reference.type-heading-5;
}

ul:not(article *) {
  list-style-type: none;
  padding-inline-start: 0;
}

main:not([class]) {
  --main-margin-block-start: 2rem;
  --main-margin-inline: 2rem;

  container-type: inline-size;
  display: flex;
  flex-direction: column;
  margin-block: var(--main-margin-block-start);
  margin-inline: auto;
  max-width: calc(100vw - var(--main-margin-inline) * 2);
  row-gap: 5rem;
  width: 82rem;

  @media (min-width: reference.$breakpoint-md) {
    --main-margin-block-start: 5rem;
  }
}

/* Tailwind-inspired utilities */

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-col {
  @include reference.flex-column;
}

.m-flex-col {
  @media (max-width: #{reference.$breakpoint-lg}) {
    @include reference.flex-column;
  }
}

.content-center {
  align-items: center;
}

.gap-sm {
  gap: reference.$space-s;
}

.gap-lg {
  gap: reference.$space-m;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.h-auto {
  height: auto;
}

.justify-between {
  justify-content: space-between;
}

.mb-sm {
  margin-block-end: reference.$space-s;
}

.mb-lg {
  margin-block-end: reference.$space-l;
}

.mt-sm {
  margin-block-start: reference.$space-s;
}

.mt-lg {
  margin-block-start: reference.$space-l;
}

.my-sm {
  margin-block: reference.$space-s;
}

.my-lg {
  margin-block: reference.$space-l;
}

.mx-auto {
  margin-inline: auto;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-lg {
  font-size: reference.$font-size-body-large;
}

.w-push {
  --push-amount: #{reference.$space-3xl};

  margin-inline: calc(var(--push-amount) * -0.5);
  max-width: 95vw;
  width: calc(100% + var(--push-amount));
}

.w-readable {
  @include reference.readable-width;
}
